import React, { useState, useEffect } from 'react';
import { AlertTriangle, Shield, Lock, CheckCircle, XCircle, Globe, Headset, TrendingUp, Clock, Wallet } from 'lucide-react';
import AIAnalysis from './aiscan';

const API_BASE = 'https://api.rugcheck.xyz/v1';
const SOLANA_TRACKER_API = 'https://data.solanatracker.io';
const API_KEY = "863b8045-2a9f-4fdb-805d-e58597bd5b4e";


const TokenSecurity = ({ tokenAddress }) => {
  const [analysis, setAnalysis] = useState(null);
  const [tokenData, setTokenData] = useState(null);
  const [holderData, setHolderData] = useState(null);
  const [athData, setAthData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const calculateAdjustedScore = (baseScore, marketCap, topHolders) => {
    let adjustedScore = Math.min(baseScore, 5);
    
    if (marketCap && marketCap < 50000) {
      adjustedScore *= 0.9;
    }
    
    if (topHolders && topHolders.length > 0) {
      const topHoldersPercentage = topHolders
        .slice(0, 5)
        .reduce((sum, holder) => sum + (holder.percentage || 0), 0);
      
      if (topHoldersPercentage > 25) {
        adjustedScore *= 0.9;
      }
    }
    
    return Math.round(adjustedScore);
  };

  useEffect(() => {
    const fetchAllData = async () => {
      if (!tokenAddress) return;
      
      setIsLoading(true);
      setError(null);
      
      try {
        const headers = { 'x-api-key': API_KEY };
        
        const [rugcheckResponse, tokenResponse, holdersResponse, athResponse] = await Promise.all([
          fetch(`${API_BASE}/tokens/${tokenAddress}/report/summary`),
          fetch(`${SOLANA_TRACKER_API}/tokens/${tokenAddress}`, { headers }),
          fetch(`${SOLANA_TRACKER_API}/tokens/${tokenAddress}/holders/top`, { headers }),
          fetch(`${SOLANA_TRACKER_API}/tokens/${tokenAddress}/ath`, { headers })
        ]);

        // const SOLANA_TRACKER_API = 'https://data.solanatracker.io'; //this is the API endpoint we're retrieving data from
        // const API_KEY = "Your API KEY HERE"; // your API key is issued to you by solana tracker when you sign up, you put it here
        // try {
        //   const headers = { 'x-api-key': API_KEY }; // this is adding your key to the request headers
        //   const tokenResponse = await Promise(fetch(`${SOLANA_TRACKER_API}/tokens/${tokenAddress}`, { headers })) //here we can ask solana tracker for data for a specific token address
        //   const tokenData = tokenResponse.ok ? tokenResponse.json() : null; // if we get an OK (success response) from the server then we set the data to the response JSON
        //   const marketCap = tokenData?.pools?.[0]?.marketCap?.usd; // here's an example of getting the market cap in USD once we have the token data
        // } catch (err) {
        //   console.log(error); // if the try block fails the error will display in the console
        // }

        if (!rugcheckResponse.ok) throw new Error('Failed to fetch security data');

        const [rugcheckData, tokenData, holderData, athData] = await Promise.all([
          rugcheckResponse.json(),
          tokenResponse.ok ? tokenResponse.json() : null,
          holdersResponse.ok ? holdersResponse.json() : null,
          athResponse.ok ? athResponse.json() : null
        ]);

        if (tokenAddress.toLowerCase().endsWith('pump')) {
          rugcheckData.tokenType = 'Pump.Fun';
        }

        if (rugcheckData.score) {
          rugcheckData.score = calculateAdjustedScore(
            rugcheckData.score,
            tokenData?.pools?.[0]?.marketCap?.usd,
            holderData
          );
        }

        setAnalysis(rugcheckData);
        setTokenData(tokenData);
        setHolderData(holderData);
        setAthData(athData);
      } catch (err) {
        setError(err.message || 'Failed to analyze token');
      } finally {
        setIsLoading(false);
      }
    };

    fetchAllData();
  }, [tokenAddress]);

  const getRiskColor = (risk) => {
    switch(risk.level.toUpperCase()) {
      case 'LOW': return 'text-purple-500';
      case 'MEDIUM': return 'text-yellow-500';
      case 'HIGH': return 'text-red-500';
      default: return 'text-gray-500';
    }
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return 'Now';
    return new Date(timestamp).toLocaleString();
  };

  const formatUSD = (value) => {
    if (!value) return '$0.00';
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 6
    }).format(value);
  };

  if (isLoading) {
    return (
      <div className="font-mono bg-black/80 border border-purple-500/50 p-4 rounded-lg w-full max-w-3xl">
        <div className="flex items-center space-x-2">
          <div className="animate-ping h-2 w-2 bg-purple-500 rounded-full" />
          <span className="text-purple-500">Analyzing token security...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="font-mono bg-black/80 border border-red-500/50 p-4 rounded-lg w-full max-w-3xl">
        <div className="flex items-center space-x-2 text-red-500">
          <AlertTriangle className="h-5 w-5 flex-shrink-0" />
          <span className="truncate">{error}</span>
        </div>
      </div>
    );
  }

  if (!analysis) return null;

  return (
<div class="flex items-center justify-center">
  <div className="font-mono bg-black/80 border border-purple-500/50 p-4 rounded-lg space-y-4 w-full max-w-3xl max-h-[80vh] overflow-y-auto">
      {/* Header */}
      <div className="flex items-center justify-between border-b border-purple-500/30 pb-2 sticky top-0 bg-black/80 z-10">
        <div className="flex items-center space-x-2">
          <Shield className="h-5 w-5 text-purple-500 flex-shrink-0" />
          <span className="text-white truncate max-w-[200px]">Token Security Analysis</span>
        </div>
      </div>

      {/* Token Info */}
      <div className="grid grid-cols-2 gap-4 text-sm">
        <div className="space-y-2">
          <p className="text-purple-500/70 truncate">
            Name: <span className="text-white">
              {tokenData?.token?.name || analysis.tokenMeta?.name || 'Unknown'}
            </span>
          </p>
          <p className="text-purple-500/70 truncate">
            Symbol: <span className="text-white">
              {tokenData?.token?.symbol || analysis.tokenMeta?.symbol || 'Unknown'}
            </span>
          </p>
          <p className="text-purple-500/70 truncate">
            Decimals: <span className="text-white">
              {tokenData?.token?.decimals || analysis.tokenMeta?.decimals || 'Unknown'}
            </span>
          </p>
        </div>
        <div className="space-y-2">
          <p className="text-purple-500/70 truncate">
            Type: <span className="text-white">{analysis.tokenType || 'Standard'}</span>
          </p>
          <p className="text-purple-500/70 truncate">
            Creator: <span className="text-white">
              {tokenData?.token?.creator?.name || 'Unknown'}
            </span>
          </p>
          <p className="text-purple-500/70 truncate">
            Program: <span className="text-white">{analysis.tokenProgram}</span>
          </p>
        </div>
      </div>

      {/* Market Stats */}
      <div className="border border-purple-500/20 rounded p-2">
        <div className="flex items-center justify-between mb-2">
          <span className="text-purple-500 font-bold">Market Stats</span>
          <div className="flex items-center space-x-2">
            <Clock className="h-4 w-4 text-purple-500" />
            <span className="text-xs text-gray-400">
              24h Change: {tokenData?.events?.['24h']?.priceChangePercentage?.toFixed(2) || '0'}%
            </span>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-x-4 gap-y-2 text-sm">
          <p className="text-purple-500/70">
            Market Cap: <span className="text-white">
              {formatUSD(tokenData?.pools?.[0]?.marketCap?.usd)}
            </span>
          </p>
          <p className="text-purple-500/70">
            Total Liquidity: <span className="text-white">
              {formatUSD(tokenData?.pools?.[0]?.liquidity?.usd)}
            </span>
          </p>
          <p className="text-purple-500/70">
            Price (USD): <span className="text-white">
              {formatUSD(tokenData?.pools?.[0]?.price?.usd)}
            </span>
          </p>
          <p className="text-purple-500/70">
            LP Burn: <span className="text-white">{tokenData?.lpBurn || 0}%</span>
          </p>
        </div>
      </div>

      {/* Holder Analysis */}
      {holderData && holderData.length > 0 && (
        <div className="border border-purple-500/20 rounded p-2">
          <div className="flex items-center justify-between mb-2">
            <span className="text-purple-500 font-bold">Top Holders</span>
            <div className="flex items-center space-x-2">
              <Wallet className="h-4 w-4 text-purple-500" />
              <span className="text-xs text-gray-400">
                Total Holders: {tokenData?.total?.toLocaleString() || 'Loading...'}
              </span>
            </div>
          </div>
          <div className="space-y-2 max-h-48 overflow-y-auto">
            {holderData.slice(0, 5).map((holder, index) => (
              <div key={index} className="grid grid-cols-3 gap-2 text-sm">
                <span className="text-gray-400 truncate max-w-[200px]">
                  {holder.address}
                </span>
                <span className="text-right text-white">
                  {holder.percentage?.toFixed(2) || '0'}%
                </span>
                <span className="text-right text-purple-500">
                  {formatUSD(holder.value?.usd)}
                </span>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Price History */}
      {athData && (
        <div className="border border-purple-500/20 rounded p-2">
          <div className="flex items-center justify-between mb-2">
            <span className="text-purple-500 font-bold">Price History</span>
            <TrendingUp className="h-4 w-4 text-purple-500" />
          </div>
          <div className="grid grid-cols-2 gap-2 text-sm">
            <div>
              <p className="text-purple-500/70">All Time High</p>
              <p className="text-white">{formatUSD(athData.highest_price)}</p>
              <p className="text-xs text-gray-400">{formatDate(athData.timestamp)}</p>
            </div>
            <div>
              <p className="text-purple-500/70">Price Changes</p>
              <div className="space-y-1">
                {['1h', '24h', '7d'].map(period => (
                  tokenData?.events?.[period]?.priceChangePercentage && (
                    <p key={period} className="text-xs">
                      <span className="text-gray-400">{period}:</span>
                      <span className={`ml-1 ${
                        tokenData.events[period].priceChangePercentage > 0 
                          ? 'text-purple-500' 
                          : 'text-red-500'
                      }`}>
                        {tokenData.events[period].priceChangePercentage.toFixed(2)}%
                      </span>
                    </p>
                  )
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Transaction Stats */}
      <div className="border border-purple-500/20 rounded p-2">
        <div className="flex items-center justify-between mb-2">
          <span className="text-purple-500 font-bold">Transaction Stats</span>
          <div className="flex items-center space-x-2">
            <Shield className="h-4 w-4 text-purple-500" />
            <span className="text-xs text-gray-400">
              Last Updated: {formatDate(tokenData?.lastUpdated)}
            </span>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-2 text-sm">
          <div>
            <p className="text-purple-500/70">Buys</p>
            <p className="text-white">{tokenData?.buys?.toLocaleString() || '0'}</p>
          </div>
          <div>
            <p className="text-purple-500/70">Sells</p>
            <p className="text-white">{tokenData?.sells?.toLocaleString() || '0'}</p>
          </div>
          <div>
            <p className="text-purple-500/70">Total Txns</p>
            <p className="text-white">{tokenData?.txns?.toLocaleString() || '0'}</p>
          </div>
        </div>
        {tokenData?.status && (
          <div className="mt-2 text-xs">
            <span className="text-purple-500/70">Status: </span>
            <span className="text-white capitalize">{tokenData.status}</span>
          </div>
        )}
      </div>

      {/* Security Status */}
      {(analysis.verification || tokenData?.token?.security) && (
        <div className="border border-purple-500/20 rounded p-2">
          <div className="flex items-center justify-between mb-2">
            <span className="text-purple-500 font-bold">Security Status</span>
            <Lock className="h-4 w-4 text-purple-500" />
          </div>
          <div className="space-y-2">
            {analysis.verification?.description && (
              <p className="text-sm text-gray-400 break-words">
                {analysis.verification.description}
              </p>
            )}
            {tokenData?.token?.security && (
              <div className="grid grid-cols-2 gap-2 text-xs">
                {tokenData.token.security.freezeAuthority && (
                  <p className="text-yellow-500/70">
                    Freeze Authority: <span className="text-white truncate block">
                      {tokenData.token.security.freezeAuthority}
                    </span>
                  </p>
                )}
                {tokenData.token.security.mintAuthority && (
                  <p className="text-yellow-500/70">
                    Mint Authority: <span className="text-white truncate block">
                      {tokenData.token.security.mintAuthority}
                    </span>
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
      )}

      {/* Social Links */}
      {tokenData?.token?.extensions && (Object.keys(tokenData.token.extensions).length > 0) && (
        <div className="border border-purple-500/20 rounded p-2">
          <div className="flex items-center justify-between mb-2">
            <span className="text-purple-500 font-bold">Social Links</span>
            <Globe className="h-4 w-4 text-purple-500" />
          </div>
          <div className="flex flex-wrap gap-2">
            {tokenData.token.extensions.twitter && (
              <a
                href={`https://twitter.com/${tokenData.token.extensions.twitter}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-purple-500 hover:text-purple-400 transition-colors text-sm flex items-center space-x-1"
              >
                <svg className="h-4 w-4" viewBox="0 0 24 24" fill="currentColor">
                  <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/>
                </svg>
                <span>Twitter</span>
              </a>
            )}
            {tokenData.token.extensions.telegram && (
              <a
                href={tokenData.token.extensions.telegram}
                target="_blank"
                rel="noopener noreferrer"
                className="text-purple-500 hover:text-purple-400 transition-colors text-sm flex items-center space-x-1"
              >
                <svg className="h-4 w-4" viewBox="0 0 24 24" fill="currentColor">
                  <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm5.894 8.221l-1.97 9.28c-.145.658-.537.818-1.084.508l-3-2.21-1.446 1.394c-.14.18-.357.223-.548.223l.196-2.755 5.014-4.525c.226-.198-.054-.31-.346-.113l-6.19 3.9-2.573-.802c-.566-.176-.576-.566.118-.84l10.03-3.864c.47-.176.878.107.8.804z"/>
                </svg>
                <span>Telegram</span>
              </a>
            )}
            {tokenData.token.creator?.site && (
              <a
                href={tokenData.token.creator.site}
                target="_blank"
                rel="noopener noreferrer"
                className="text-purple-500 hover:text-purple-400 transition-colors text-sm flex items-center space-x-1"
              >
                <Globe className="h-4 w-4" />
                <span>Website</span>
              </a>
            )}
          </div>
        </div>
      )}
<AIAnalysis tokenData={tokenData} analysis={analysis} />

      {/* Warning Messages */}
      {(analysis.rugged || tokenData?.risk?.rugged || analysis.score < 5) && (
        <div className="bg-red-500/20 border border-red-500/50 rounded p-2 mt-4">
          <div className="flex items-center space-x-2 text-red-500">
            <AlertTriangle className="h-5 w-5 flex-shrink-0" />
            <span className="font-bold text-sm">
              {analysis.rugged || tokenData?.risk?.rugged 
                ? 'WARNING: This token has been marked as rugged!'
                : 'WARNING: This token has a very low security score!'}
            </span>
          </div>
          {tokenData?.risk?.risks && (
            <div className="mt-2 space-y-1">
              {tokenData.risk.risks.map((risk, index) => (
                <p key={index} className="text-xs text-gray-400">
                  • {risk.description}
                </p>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
    </div>

  );
};

export default TokenSecurity;