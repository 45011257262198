import React, { useState, useEffect } from 'react';
import { Terminal, TrendingUp, Bot, Database, Shield, Send, AlertTriangle, Copy as CopyIcon, Check as CheckIcon } from 'lucide-react';
import TokenSecurity from './components/rugcheck';
import LaunchPad from './components/launchpad';

const base58Regex = /^[1-9A-HJ-NP-Za-km-z]{32,44}$/;

const TipAI = () => {
  const [prompt, setPrompt] = useState('');
  const [response, setResponse] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [tokenAddress, setTokenAddress] = useState(null);
  const [error, setError] = useState('');
  const [countdown, setCountdown] = useState(0);
  const [copied, setCopied] = useState(false);
  const [winRate, setWinRate] = useState(65);
  const [responseTime, setResponseTime] = useState(0);
  const [dataProcessed, setDataProcessed] = useState(0);
  const [monitoring, setMonitoring] = useState("24/7");

  const contractAddress = "scanT4oKqxVBuuygkBmmnPVownvAqEEycD9aPMxLx6V";

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(contractAddress);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const validateInput = (input) => {
    if (!input.trim()) {
      setError('Please enter a token address');
      return false;
    }
    if (!base58Regex.test(input.trim())) {
      setError('Please enter a valid Solana token address (base58 format)');
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [countdown]);

  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }
      ::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.3);
        border-radius: 4px;
      }
      ::-webkit-scrollbar-thumb {
        background: rgba(168, 85, 247, 0.5);
        border-radius: 4px;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: rgba(168, 85, 247, 0.5);
      }
    `;
    document.head.appendChild(style);
    return () => document.head.removeChild(style);
  }, []);

  // Response time updates every 200ms
  useEffect(() => {
    const responseInterval = setInterval(() => {
      setResponseTime(() => Math.floor(Math.random() * (800 - 400) + 400));
    }, 200);

    return () => clearInterval(responseInterval);
  }, []);

  // Win Rate updates every 2 seconds
  useEffect(() => {
    const updateInterval = setInterval(() => {
      setWinRate(prev => {
        const change = (Math.random() * 0.4 - 0.2);
        const newRate = prev + change;
        if (newRate > 70) return 70;
        if (newRate < 60) return 60;
        return newRate;
      });
    }, 2000);

    return () => clearInterval(updateInterval);
  }, []);

  // Data processed increases by 0.05 every 2 seconds
  useEffect(() => {
    const dataInterval = setInterval(() => {
      setDataProcessed(prev => prev + 0.05);
    }, 2000);

    return () => clearInterval(dataInterval);
  }, []);

  // Initial monitoring animation
  useEffect(() => {
    const monitoringSteps = ["3/1", "6/2", "12/4", "24/7"];
    let step = 0;
    const monitoringInterval = setInterval(() => {
      if (step >= monitoringSteps.length) {
        clearInterval(monitoringInterval);
        return;
      }
      setMonitoring(monitoringSteps[step]);
      step++;
    }, 500);

    return () => clearInterval(monitoringInterval);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    
    if (!validateInput(prompt)) return;
    
    setCountdown(2);
    setIsTyping(true);
    setTokenAddress(null);

    await new Promise(resolve => setTimeout(resolve, 2000));
    
    const mockResponse = `Analyzing ${prompt.toUpperCase()}...\n\nInitiating security scan...`;
    
    for (let i = 0; i <= mockResponse.length; i++) {
      await new Promise(resolve => setTimeout(resolve, 30));
      setResponse(mockResponse.slice(0, i));
    }
    
    setTokenAddress(prompt);
    setIsTyping(false);
  };

  const stats = [
    { 
      icon: <TrendingUp className="h-6 w-6" />, 
      value: `${winRate.toFixed(1)}%`, 
      label: "Win Rate" 
    },
    { 
      icon: <Bot className="h-6 w-6" />, 
      value: `${responseTime}ms`, 
      label: "Response Time" 
    },
    { 
      icon: <Database className="h-6 w-6" />, 
      value: `${dataProcessed.toFixed(2)}GB+`, 
      label: "Data Processed" 
    },
    { 
      icon: <Shield className="h-6 w-6" />, 
      value: monitoring, 
      label: "Monitoring" 
    }
  ];

  return (
    <div className="min-h-screen bg-black text-purple-500 relative">
      {/* Grid Background - Increased opacity and modified structure */}
      <div className="absolute inset-0">
        <div 
          className="absolute inset-0 bg-[linear-gradient(to_right,rgba(56,189,248,0.2)_1px,transparent_1px),linear-gradient(to_bottom,rgba(56,189,248,0.2)_1px,transparent_1px)]"
          style={{
            backgroundSize: '64px 64px',
            backgroundPosition: '0 0'
          }}
        />
      </div>

      <div className="relative z-10">
        {/* Header */}
        <header className="flex justify-between items-center py-4 px-4 border-b border-purple-500/30">
          <div className="flex items-center space-x-2">
            <Terminal className="h-8 w-8" />
            <span className="text-2xl font-mono font-bold">intelai.fun</span>
          </div>
        </header>

        <div className="max-w-7xl mx-auto px-4">
          <div className="mt-8 flex flex-col items-center text-center">
            <div className="w-full max-w-2xl bg-black/80 border border-purple-500/30 rounded-lg p-4 mb-8">
              <div className="flex items-center justify-between gap-4">
                <div className="flex-1 bg-black/50 border border-purple-500/30 rounded px-4 py-2 font-mono text-lg">
                  {`${contractAddress.slice(0, 5)}...${contractAddress.slice(-5)}`}
                </div>
                <button
                  onClick={handleCopy}
                  className="flex items-center gap-2 bg-purple-500/20 border border-purple-500/30 px-4 py-2 rounded transition-all hover:bg-purple-500/30"
                >
                  {copied ? (
                    <>
                      <span className="font-mono">Copied!</span>
                      <CheckIcon className="h-5 w-5" />
                    </>
                  ) : (
                    <>
                      <span className="font-mono">Copy CA</span>
                      <CopyIcon className="h-5 w-5" />
                    </>
                  )}
                </button>
              </div>
            </div>

            <img src="/images/icons8-brain-96.png" className="w-1/8" />
            <h1 className="text-4xl md:text-6xl font-bold font-mono mb-6">
              AI analytics meet Pump.fun.
            </h1>
            <p className="text-xl mb-8 text-purple-400 max-w-2xl">
              Leverage custom algorithms and advanced ML models for precision trading on Pump.fun.
            </p>

            {/* Terminal Section - Now Wider */}
            <div className="font-mono bg-black/80 border border-purple-500/50 p-4 md:p-6 rounded-lg w-full max-w-4xl">
              <form onSubmit={handleSubmit} className="flex gap-2 mb-4">
                <div className="flex-1 relative">
                  <span className="absolute left-2 top-3 text-purple-500/50">$</span>
                  <input
                    type="text"
                    value={prompt}
                    onChange={(e) => {
                      setPrompt(e.target.value);
                      setError('');
                    }}
                    placeholder="Enter token address (e.g., So11111111111111111111111111111111111111112)"
                    className="w-full bg-black/50 border border-purple-500/30 rounded px-6 py-3 text-purple-500 placeholder-purple-500/30 focus:outline-none focus:border-purple-500 text-base md:text-lg"
                    disabled={isTyping || countdown > 0}
                  />
                </div>
                <button
                  type="submit"
                  className={`bg-purple-500/20 border border-purple-500/30 p-3 rounded transition-colors ${
                    isTyping || countdown > 0 
                      ? 'opacity-50 cursor-not-allowed' 
                      : 'hover:bg-purple-500/30'
                  }`}
                  disabled={isTyping || countdown > 0}
                >
                  <Send className="h-6 w-6" />
                </button>
              </form>

              

              {/* Error Message */}
              {error && (
                <div className="flex items-center justify-center space-x-2 text-red-500 mb-4">
                  <AlertTriangle className="h-4 w-4" />
                  <span className="text-sm">{error}</span>
                </div>
              )}

              {/* Countdown Warning */}
              {countdown > 0 && (
                <div className="flex items-center justify-center space-x-2 text-yellow-500 mb-4">
                  <AlertTriangle className="h-4 w-4" />
                  <span className="text-sm">Analysis will begin in {countdown} seconds...</span>
                </div>
              )}

              {/* Response Area */}
              <div className="min-h-[200px] whitespace-pre-line max-h-[60vh] overflow-y-auto p-4 bg-black/30 rounded-lg mb-0">
                {response}
                {isTyping && <span className="animate-pulse">_</span>}
              </div>
              
              {/* TokenSecurity Component */}
              {tokenAddress && <TokenSecurity tokenAddress={tokenAddress} />}
            </div>
            <div className="mt-16 mb-16">
            <LaunchPad />
            </div>
          </div>

          {/* Stats Grid */}
          <div className="mt-16 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
            {stats.map((stat, i) => (
              <div key={i} className="bg-black/50 border border-purple-500/30 p-4 rounded-lg">
                <div className="flex items-center space-x-2 mb-2">
                  {stat.icon}
                  <span className="text-2xl font-bold">{stat.value}</span>
                </div>
                <p className="text-purple-400 font-mono">{stat.label}</p>
              </div>
            ))}
          </div>

          {/* Features Grid */}
          <div className="mt-16 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 pb-16">
            {[
              "Real-time Market Analysis",
              "Predictive Modeling",
              "Risk Management",
              "Portfolio Optimization",
              "Automated Trading",
              "Performance Analytics"
            ].map((feature, i) => (
              <div
                key={i}
                className="bg-black/50 border border-purple-500/30 p-4 rounded-lg hover:bg-purple-500/5 transition-colors"
              >
                <h3 className="font-mono text-lg mb-2">{feature}</h3>
                <div className="h-1 w-12 bg-purple-500/50" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TipAI;